import Vuex from "vuex";
import Vuelidate from "vuelidate";
import VueI18n from "vue-i18n";
import VueRouter from "vue-router";
import VueLazyload from "vue-lazyload";
import { ITestConfig, IComponentMocks, TestComponent } from "./models";
import { testMyRentalsShallowMount as testMounted } from "./matchers";
import { config, mount, shallowMount, createLocalVue } from "@vue/test-utils";
import FakeConsole from "@/tests/unit/FakeConsole";

config.mocks.$t = (key: string) => key;
config.mocks.$tc = (key: string, value: number) => {
  return {
    key,
    value,
  };
};

window.console = FakeConsole;

export const userAgent = jest.spyOn(navigator, "userAgent", "get");

export const deepCopy = (object: any) => JSON.parse(JSON.stringify(object));

export const myRentalsMount = (
  _component: any,
  mocks: ITestConfig = {}
): TestComponent => mount(_component, createComponentMocks(mocks));

export const myRentalsShallowMount = (
  _component: any,
  mocks: ITestConfig = {}
): TestComponent => shallowMount(_component, createComponentMocks(mocks));

export const testMyRentalsShallowMount = (
  _component: any,
  mocks: ITestConfig = {}
) => testMounted(_component, mocks);

const createComponentMocks = ({
  data = {},
  props = {},
  store,
  style,
  attachDocument,
  stubs = {},
  mocks = {},
  provide = {},
  router,
  routes,
}: ITestConfig): IComponentMocks => {
  const localVue = createLocalVue();
  localVue.use(Vuelidate);

  // MQ and filters
  localVue.mixin({ methods: { mq: jest.fn(() => true) } });
  localVue.filter(
    "currency",
    jest.fn((a) => a)
  );
  localVue.filter(
    "number",
    jest.fn((a) => a)
  );
  localVue.filter(
    "numberSymbol",
    jest.fn((a) => a)
  );

  const returnOptions: IComponentMocks = { localVue };

  returnOptions.data = () => ({ ...data });
  returnOptions.propsData = { ...props };

  localVue.use(VueI18n);
  const i18n = new VueI18n({
    locale: "es",
    fallbackLocale: "es",
    messages: { es: {} },
    silentTranslationWarn: true,
  });
  returnOptions.i18n = i18n;

  if (attachDocument) {
    const elem = document.createElement("div");
    if (document.body) document.body.appendChild(elem);
    returnOptions.attachTo = elem;
  }

  if (store) {
    localVue.use(Vuex);
    returnOptions.store = new Vuex.Store(store);
  }

  returnOptions.stubs = stubs;
  returnOptions.mocks = mocks;
  returnOptions.provide = provide;

  if (router) {
    returnOptions.stubs["router-link"] = true;
    returnOptions.stubs["router-view"] = true;
    localVue.use(VueRouter);

    const options: any = {};
    if (routes) {
      options.routes = routes;
    }
    router = new VueRouter(options);
    returnOptions.router = router;
  }

  localVue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1,
    throttleWait: 400,
  });

  if (style) returnOptions.mocks.$style = style;

  return returnOptions;
};
