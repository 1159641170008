import { myRentalsShallowMount } from "@/tests/unit/setup";
import { ITestConfig } from "./models";

export const testMyRentalsShallowMount = (
  component: any,
  opts: ITestConfig = {}
) => {
  it(`${component.name} mounts correctly`, () => {
    // given
    // when
    const wrapper = myRentalsShallowMount(component, opts);
    // then
    expect(wrapper.exists()).toBe(true);
  });
};
